.input-group{
  .form-control{
    border-right: 0 solid transparent !important;
    &:focus{
      box-shadow: 0 transparent !important;
    }
  }
  .input-group-text{
    background-color: #ffffff;
    border-left: 0 solid transparent !important;
    border-radius: 0 10px 10px 0;
  }
  &:hover{
    .form-control{
      border: 1px solid #383838;
      box-shadow: 0 !important;
    }
    .input-group-text{
      border: 1px solid #383838;
      box-shadow: 0 !important;
    }
  }
  &:focus{
    .form-control {
      border: 1px solid #383838;
      box-shadow: 0 !important;
    }
    .input-group-text {
      border: 1px solid #383838;
      box-shadow: 0 !important;
    }
  }
}
.form-control {
  padding: 14px 20px;
  font-size: 12px;
  font-family: 'Montserrat-Medium', 'sans-serif';
  border-radius: 10px;
  &:hover {
    border: 1px solid #383838;
    box-shadow: 0px 6px 15px rgrgba(0, 0, 0, 0.076)
  }
  &:focus {
    border: 1px solid #383838;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.152);
  }
}
.form-upload{
  .file {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .file>input[type='file'] {
    display: none
  }
  .file>label {
    font-size: 1rem;
    cursor: pointer;
    outline: 0;
    font-family: 'Montserrat-Medium', 'sans-serif';
    user-select: none;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    filter: drop-shadow(0px 7px 15px rgba(0, 0, 0, 0.07));
    .upload-title{
      font-size: 16px;
      color: #CB2027;
    }
    .file-name{
      font-size: 12px;
      color: #CB2027;
      white-space: nowrap;
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .upload-information{
      font-size: 10px;
    }
    .invalid{
      border: 1px solid #CB2027;
      filter: drop-shadow(0px 7px 15px rgba(255, 0, 0, 0.07));
    }
    &.file-preview{
      background: #FFFFFF;
      border: 0 solid transparent;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
    }
  }
  .file>label:hover {
    border-color: hsl(0, 0%, 21%);
  }
  .file>label:active {
    background-color: hsl(0, 0%, 96%);
  }
  .file--disabled {
    cursor: not-allowed;
  }
  .file--disabled>label {
    border-color: #e6e7ef;
    color: #e6e7ef;
    pointer-events: none;
  }
  @keyframes pulse {
    0% {
      color: hsl(48, 100%, 67%);
    }
    50% {
      color: hsl(48, 100%, 38%);
    }
    100% {
      color: hsl(48, 100%, 67%);
    }
  }
}