.career{
  h1 {
    font-size: 30px;
    font-family: 'Montserrat-SemiBold', 'open-sans';
    span {
      font-size: 30px;
      font-family: 'Montserrat-SemiBold', 'open-sans';
    }
  }
  p{
    font-size: 12px;
    line-height: 24px;
  }
  .accordion{
    .accordion-item{
      background: #FFFFFF;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
      border-radius: 20px;
      border: 0;
      margin-bottom: 15px;
      .accordion-button{
        background: transparent;
        color: #000000;
        .job-title{
          font-size: 14px;
          font-family: 'Montserrat-SemiBold', 'sans-serif';
        }
        .job-location{
          font-size: 14px;
          font-family: 'Montserrat-Regular', 'sans-serif';
          span{
            font-size: 14px;
            font-family: 'Montserrat-Regular', 'sans-serif';
          }
        }
        &:focus{
          box-shadow: unset;
        }
        &:after{
          background-image: url('../../img/accordion-arrow-red.svg');
        }
        &:not(.collapsed){
          box-shadow: unset;
          &:after{
            transform: rotate(90deg);
          }
        }
      }
      .accordion-body{
        font-size: 12px;
        font-family: 'Montserrat-Regular', 'sans-serif';
        line-height: 20px;
        span{
          font-size: 12px;
          font-family: 'Montserrat-SemiBold', 'sans-serif';
          line-height: 20px;
          margin-bottom: 10px;
        }
        ol{
          padding-left: 15px;
          li {
            font-size: 12px;
            font-family: 'Montserrat-Regular', 'sans-serif';
            line-height: 20px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .card-left{
    min-height: 500px;
  }
  .img-sticky{
    top: 100px;
  }
}