.job{
  .job-lists{
    .company-logo {
      width: 90px;
      height: 90px;
      background: #FFFFFF;
      border: 1px solid #E9E9E9;
      border-radius: 10px;
      padding: 5px;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    h3{
      font-size: 16px;
    }
    p{
      font-size: 12px;
      line-height: 24px;
    }
  }
  .title{
    font-size: 16px;
  }
  p, li{
    font-size: 12px;
    line-height: 24px;
  }
  .badge{
    border-radius: 100px;
    padding: 8px 15px;
    font-size: 12px;
    font-family: 'Montserrat-SemiBold', 'sans-serif';
    &.badge-red{
      background-color: #FDF2F2;
      color: #9E5C5C;
    }
  }
  .card-action{
    top: 100px;
  }
  .card-left{
    min-height: 500px;
  }
  .title-job{
    font-size: 18px;
    font-family: 'Montserrat-SemiBold', 'sans-serif';
  }
  h4{
    font-size: 12px;
    font-family: 'Montserrat-SemiBold', 'sans-serif';
  }
  .review-profile{
    span{
      font-size: 12px;
      color: #929292;
    }
    h6{
      font-family: 'Montserrat-SemiBold', 'sans-serif';
      color: #333333;
    }
  }
}

@media only screen and (max-width: 767px) {
  .job{
    .card-action {
      top: 0px;
      margin-bottom: 90px;
    }
    .title-job {
      font-size: 18px !important;
      font-family: 'Montserrat-SemiBold', 'sans-serif';
    }
  }
}
.card-search{
  position: absolute;
  height: 200px;
  z-index: 1000;
  width: 95%;
  border: 1px solid #e1e1e1;
  font-family: 'Montserrat-Medium', ' sans-serif';
  font-size: 12px;
  top: 50px;
  overflow-y: auto;
}
.search-result{
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  font-family: 'Montserrat-Regular', ' sans-serif';
  background-color: transparent;
  font-size: 14px;
  display: block;
  transition: 0.8s;
  &:hover, &:focus{
    background-color: #eeeeee;
  }
}