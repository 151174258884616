.contact-us{
  h1 {
    font-size: 30px;
    font-family: 'Montserrat-SemiBold', 'open-sans';
    span {
      font-size: 30px;
      font-family: 'Montserrat-SemiBold', 'open-sans';
    }
  }
  p{
    font-size: 12px;
    line-height: 24px;
  }
}