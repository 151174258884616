.who-we-are{
  overflow-x: hidden;
  padding-top: 100px;
  h1 {
    font-size: 70px;
    font-family: 'Montserrat-ExtraBold', 'open-sans';
    span {
      font-size: 70px;
      font-family: 'Montserrat-ExtraBold', 'open-sans';
    }
  }
  h3 {
    font-size: 28px;
    font-family: 'Montserrat-SemiBold', 'open-sans';
    span {
      font-size: 28px;
      font-family: 'Montserrat-SemiBold', 'open-sans';
    }
  }
}

@media only screen and (max-width: 767px) {
  .who-we-are {
    padding-top: 20px;
  }
}