@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('../../font/montserrat/static/Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-Black';
  src: url('../../font/montserrat/static/Montserrat-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-ExtraLight';
  src: url('../../font/montserrat/static/Montserrat-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-Light';
  src: url('../../font/montserrat/static/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('../../font/montserrat/static/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'montserrat-regular';
  src: url('../../font/montserrat/static/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('../../font/montserrat/static/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-Thin';
  src: url('../../font/montserrat/static/Montserrat-Thin.ttf') format('truetype');
}