.what-we-do{
  overflow-x: hidden;
  h1 {
    font-size: 70px;
    font-family: 'Montserrat-ExtraBold', 'open-sans';
    span {
      font-size: 70px;
      font-family: 'Montserrat-ExtraBold', 'open-sans';
    }
  }
  .content-top-left{
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.5;
    z-index: -1;
  }
  .content-top-right{
    position: absolute;
    right: 0;
    top: -150px;
    opacity: 0.5;
    z-index: -1;
  }
  .img-what-we-do{
    width: 100%;
    height: 280px;
    object-fit: contain;
  }
  .featured{
    padding: 10px;
    &:nth-child(even) {
      background-color: #FFF8F8;
    }
    &:nth-child(odd) {
      background-color: #FAECEE;
    }
    &:first-child{
      border-radius: 30px 30px 0 0;
    }
    &:last-child{
      border-radius: 0 0 30px 30px; 
    }
    h3{
      font-size: 16px !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .img-what-we-do {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}