.p-all{
  padding-top: 80px;
}
.bg-light{
  background-color: $white !important;
}
.text-light{
  color: $white !important;
}
.h-100-vh{
  height: 100vh;
  overflow-y: auto;
}
.h-80-vh {
  height: 500px;
}
p, span{
  font-family: 'Montserrat-regular', 'sans-serif';
  font-size: 14px;
  line-height: 28px;
}
.text-red{
  color: #CB2027;
}
a{
  color: unset;
  text-decoration: unset;
  &:hover{
    color: unset;
    text-decoration: unset;
  }
}
h2 {
  font-size: 40px;
  font-family: 'Montserrat-SemiBold', 'sans-serif';
  span {
    font-size: 40px;
    font-family: 'Montserrat-SemiBold', 'sans-serif';
  }
}
h3{
  font-size: 22px;
  font-family: 'Montserrat-SemiBold', 'sans-serif';
  span {
    font-size: 22px;
    font-family: 'Montserrat-SemiBold', 'sans-serif';
  }
}
h6{
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', 'sans-serif';
  span{
    font-size: 14px;
    font-family: 'Montserrat-SemiBold', 'sans-serif';
  }
}
.bg-secondary{
  background-color: #FBFBFB !important;
}
label{
  font-family: 'montserrat-regular','sans-serif';
  &.form-check-label{
    font-size: 12px !important;
  }
}
@media (min-width: 1200px){
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1250px;
  }
}
nav{
  z-index: 12;
}
.nav-link{
  font-family: 'Montserrat-Medium', 'sans-serif';
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: $dark;
  margin: 0 5px;
  &:hover{
    color: #CB2027 !important;
  }
  &.active{
    color: #CB2027 !important;
    position: relative;
    &::after{
      content: '';
      width: 18px;
      height: 2px;
      background-color: #FFBCBF;
      border-radius: 50px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 4px;
      margin: auto;
    }
  }
}
@media only screen and (max-width: 991px) {
  .nav-link {
    font-family: 'Montserrat-Medium', 'sans-serif';
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: $dark;
    margin: 0 5px;
    &:hover {
      color: #CB2027 !important;
    }
    &.active {
      color: #CB2027 !important;
      position: relative;
      &::after {
        content: '';
        width: 18px;
        height: 2px;
        background-color: #FFBCBF;
        border-radius: 50px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 4px;
        margin: 0;
      }
    }
  }
} 
@media only screen and (max-width: 767px) {
  .nav-link{
    font-size: 18px;
  }
  h1{
    font-size: 35px !important;
    span{
      font-size: 35px !important;
    }
  }
  h3 {
    font-size: 16px !important;
    span {
      font-size: 16px !important;
    }
  }
}
.btn{
  border-radius: 10px;
  padding: 15px 30px;
  border: 0;
  font-family: 'Montserrat-SemiBold', 'sans-serif';
  transition: 0.25s;
  &.btn-md{
    font-size: 12px !important;
  }
  &.btn-lg{
    font-size: 18px !important;
  }
  &.btn-primary{
    background-color: #CB2027 !important;
    color: $white !important;
    &:hover,
    &:focus {
      box-shadow: inset 0 -4em 0 0 #B01017;
    }
  }
  &.btn-outline-primary {
    background-color: #ffffff !important;
    border: 1px solid #CB2027 !important;
    color: #CB2027  !important;
    &:hover,
    &:focus {
      box-shadow: inset 0 -4em 0 0 #B01017;
      border: 1px solid #B01017 !important;
      color: $white  !important;
    }
  }
  &.btn-login {
    background-color: #ffffff !important;
    color: #CB2027  !important;
    &:hover,
    &:focus {
      box-shadow: inset 0 -4em 0 0 #FFECED;
    }
    &.active{
      background-color: #FFECED !important;
      color: #CB2027 !important;
      &:hover,
      &:focus {
        box-shadow: inset 0 -4em 0 0 #f3d3d5;
      }
    }
  }
  &.btn-link {
    font-weight: 400;
    color: #CB2027;
    text-decoration: none;
    font-size: 12px;
    padding: 2px;
  }
  &.btn-widget-wa{
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 15;
    transform: scale(1);
    transition: 0.8s;
    &:hover{
      transform: scale(1.2);
    }
  }
  &:focus{
    box-shadow: unset;
  }
}
.toggle {
  display: block;
  cursor: pointer;
  input {
    display: none;
    &+div {
      width: 20px;
      height: 14px;
      position: relative;
      div {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transition: transform .5s ease;
        span {
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          &:first-child {
            top: 0;
            &:before,
            &:after {
              top: 0;
            }
          }
          &:last-child {
            bottom: 0;
            &:before,
            &:after {
              bottom: 0;
            }
          }
          &:before,
          &:after {
            content: '';
            display: block;
            width: 47%;
            height: 2px;
            border-radius: 1px;
            background: $dark;
            position: absolute;
            transition: transform .5s ease, border-radius .3s ease, background .4s ease;
          }
          &:before {
            left: 0;
            transform-origin: 0 50%;
            transform: translate(1px, 0) scaleX(1.1);
          }
          &:after {
            right: 0;
            transform-origin: 100% 50%;
            transform: translate(-1px, 0) scaleX(1.1);
          }
        }
      }
      svg {
        display: block;
        fill: none;
        stroke: $dark;
        stroke-width: 2px;
        width: 44px;
        height: 44px;
        stroke-linecap: round;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -22px 0 0 -22px;
        stroke-dasharray: 0 82.801 8 82.801;
        stroke-dashoffset: 82.801;
        transform-origin: 50% 50%;
        transform: scale(1);
        transition: stroke-dashoffset .5s ease, stroke-dasharray .6s ease, transform .5s ease, stroke .4s ease;
        &:nth-child(3) {
          transform: rotate(180deg) scale(1);
        }
      }
    }
    &:checked+div {
      div {
        transform: rotate(90deg);
        span {
          &:before,
          &:after {
            background: $dark;
          }
          &:first-child {
            &:before {
              transform: rotate(45deg) translate(2.2px, -3px) scaleX(1.05);
            }
            &:after {
              transform: rotate(-45deg) translate(-2.2px, -3px) scaleX(1.05);
            }
          }
          &:last-child {
            &:before {
              transform: rotate(-45deg) translate(2.2px, 3px) scaleX(1.05);
            }
            &:after {
              transform: rotate(45deg) translate(-2.2px, 3px) scaleX(1.05);
            }
          }
        }
      }
      svg {
        stroke-dashoffset: 62;
        stroke-dasharray: 0 82.801 62 82.801;
        transform: rotate(90deg);
        stroke: $dark;
        &:nth-child(3) {
          transform: rotate(270deg);
        }
      }
    }
  }
}
.mobile-menu{
  height: 0;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  background: $white;
  position: fixed;
  z-index: 10;
  transition: 1s;
  padding-top: 0;
  opacity: 0;
  &.show{
    height: 100vh;
    padding-top: 120px;
    opacity: 1;
  }
}
.card{
  border: 0;
  border-radius: 15px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  &.card-go-scout{
    background: linear-gradient(117.96deg, #FFFFFF 0%, #FFF0F1 100%);
    box-shadow: 0 transparent;
    transition: 2s;
    &:hover{
      background: linear-gradient(117.96deg, #FFFFFF 0%, #FFF0F1 32.29%);
    }
  }
  &.card-wa{
    background: #ffffff;
    position: fixed;
    bottom: 92px;
    right: 48px;
    z-index: 99999999999;
    width: 350px;
    box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.15);
    padding: 5px 0;
    .card-header{
      border-radius: 15px 15px 0 0;
      background-color: #ffffff;
      border: 0;
      .avatar{
        position: relative;
        img{
          width: 50px;
          height: 50px;
          border-radius: 100px;
          object-fit: cover;
          border: 2px solid rgba(0, 0, 0, 0.09);
        }
        .online-status{
          position: absolute;
          right: 0;
          bottom: 0;
          width: 15px;
          height: 15px;
          background-color: #51D327;
          border: 2px solid #ffffff;
          z-index: 11;
          border-radius: 50px;
        }
      }
      .title{
        span{
          font-size: 14px;
          font-family: 'Montserrat-SemiBold', 'sans-serif';
          color: #000000;
          line-height: 14px;
          &.sub-title{
            font-size: 10px;
            font-family: 'Montserrat-Regular', 'sans-serif';
            color: #626262;
          }
        }
      }
    }
    .card-body{
      height: 40vh;
      overflow-y: auto;
      background: url('../../img/wa.png');
      background-size: cover;
      background-repeat: no-repeat;
      .msg {
        width: 100%;
        height: auto;
        display: block;
        overflow: hidden;
        .bubble {
          float: left;
          max-width: 75%;
          width: auto;
          height: auto;
          display: block;
          background: #f3f3f3;
          border-radius: 5px;
          position: relative;
          margin: 10px 0 3px 10px;
          box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
          &.alt {
            margin: 10px 25px 3px 0px;
            background: #DCF8C6;
            float: right;
          }
          &.follow {
            margin: 2px 0 3px 25px;
          }
          &.altfollow {
            margin: 2px 25px 3px 0px;
            background: #DCF8C6;
            float: right;
          }
          .txt {
            padding: 8px 0 8px 0;
            width: 100%;
            min-height: 54px;
            position: relative;
            .name {
              font-weight: 600;
              font-size: 14px;
              display: inline-table;
              padding: 0 0 0 15px;
              margin: 0 0 4px 0;
              color: #3498db;
              span {
                font-weight: normal;
                color: #b3b3b3;
                overflow: hidden;
              }
              &.alt {
                color: #2ecc51;
              }
            }
            .message {
              font-size: 12px;
              font-weight: 500;
              padding: 0 15px 0 15px;
              margin: auto;
              color: #2b2b2b;
              display: table;
              line-height: 20px;
            }
            .timestamp {
              font-size: 10px;
              margin: auto;
              padding: 0 15px 0 0;
              display: table;
              float: right;
              position: absolute;
              text-transform: uppercase;
              color: #999;
              bottom: 0;
              right: 0;
            }
          }
          .bubble-arrow {
            position: absolute;
            float: left;
            left: -11px;
            top: 0px;
            &.alt {
              bottom: 20px;
              left: auto;
              right: 4px;
              float: right;
            }
          }
          .bubble-arrow:after {
            content: "";
            position: absolute;
            border-top: 15px solid #f3f3f3;
            border-left: 15px solid transparent;
            border-radius: 4px 0 0 0px;
            width: 0;
            height: 0;
          }
          .bubble-arrow.alt:after {
            border-top: 15px solid #DCF8C6;
            transform: scaleX(-1);
          }
        }
      }
    }
    .card-footer {
      border-radius: 0 0 15px 15px;
      background-color: #ffffff;
      border: 0;
      .btn-wa{
        border-radius: 100px;
        color: #ffffff;
        background: #20B038;
        padding: 10px 30px;
      }
    }
  }
}
.img-identity{
  width: 80px;
}
.img-identity-2 {
  width: 60px;
}
.swiper {
  width: 100%;
  height: 100%;
  .swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}
ol{
  li{
    font-family: 'Montserrat-regular', 'sans-serif';
    font-size: 14px;
    line-height: 28px;
  }
}
ul{
  list-style-image: url('../../img/list.svg');
  padding-left: 5px;
  li{
    font-family: 'Montserrat-regular', 'sans-serif';
    font-size: 14px;
    line-height: 28px;
    margin-left: 10px;
  }
}
.pagination{
  display: flex;
  justify-content: center;
  .page-item{
    .page-link{
      background-color: transparent;
      border: 0;
      border-radius: 5px;
      font-size: 12px;
      line-height: 12px;
      color: #000000;
      font-family: 'Montserrat-Medium', 'sans-serif';
      padding: 8px;
    }
    &.active, &:hover{
      .page-link {
        border: 0;
        border-radius: 5px;
        font-size: 12px;
        line-height: 12px;
        background-color: #CB2027;
        color: #ffffff !important;
        font-family: 'Montserrat-Medium', 'sans-serif';
        padding: 8px;
      }
    }
    &:first-child {
      .page-link{
        background-color: #CB2027;
        color: #ffffff;
        padding: 10px 12.8px;
        border-radius: 50px;
        margin-right: 15px;
        font-size: 20px;
      }
    }
    &:last-child {
      .page-link{
        background-color: #CB2027;
        color: #ffffff;
        padding: 10px 12.8px;
        border-radius: 50px;
        margin-left: 15px;
        font-size: 20px;
      }
    }
  }
}
.progress{
  border-radius: 100px;
  .progress-bar{
    border-radius: 100px;
    background-color: #CB2027;
  }
}
.information{
  font-size: 10px;
  line-height: 18px;
}
.dropdown{
  .dropdown-menu{
    border: 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin-top: 10px;
    .dropdown-item{
      font-size: 12px;
      &:hover {
        background: #FFFFFF !important;
      }
    }
  }
  .btn-dropdown{
    border-radius: 10px; 
    background-color: #f9f9f9;
  }
}
#canvas{
  display: block;
  position: fixed;
}
.img-home-content{
  transform: translateY(-50px);
}
footer{
  background: #CB2027;
  border-radius: 20px 0px 0px 0px;
  position: relative;
  h3{
    font-family: 'Montserrat-Medium', 'sans-serif';
    font-size: 16px;
    margin-bottom: 10px;
  }
  a{
    font-family: 'Montserrat-Regular', 'sans-serif';
    font-size: 12px;
    padding: 3px 0;
    text-decoration: none;
    &:hover{
      font-family: 'Montserrat-SemiBold', 'sans-serif';
    }
  }
  p{
    font-family: "Montserrat-Regular", "sans-serif";
    font-size: 12px;
  }
  .footer-img{
    position: absolute;
    right: 0;
    top: -100px;
  }
  .accordion{
    .accordion-item{
      background: transparent;
      border: unset;
      .accordion-button{
        background: transparent;
        &:focus{
          box-shadow: unset;
        }
        &:after{
          background-image: url('../../img/accordion-arrow-white.svg');
        }
        &:not(.collapsed){
          box-shadow: unset;
          &:after{
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}
.modal{
  .modal-content{
    border-radius: 10px;
    border: 0;
  }
  h5{
    font-family: 'Montserrat-SemiBold', sans-serif;
  }
  .btn-close {
    &:focus{
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
      opacity: 1;
    }
  }
}
@media only screen and (max-width: 500px) {
  .btn{
    &.btn-widget-wa{
      position: fixed;
      right: 0;
      bottom: 0;
      z-index: 15;
      transform: scale(1);
      transition: 0.8s;
      &:hover{
        transform: scale(1.2);
      }
    }
  }
  .card{
    &.card-wa{
      background: #ffffff;
      position: fixed;
      bottom: 92px;
      right: 48px;
      z-index: 99999999999;
      width: 80vw;
      box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.15);
      padding: 5px 0;
      .card-header{
        border-radius: 15px 15px 0 0;
        background-color: #ffffff;
        border: 0;
        .avatar{
          position: relative;
          img{
            width: 50px;
            height: 50px;
            border-radius: 100px;
            object-fit: cover;
            border: 2px solid rgba(0, 0, 0, 0.09);
          }
          .online-status{
            position: absolute;
            right: 0;
            bottom: 0;
            width: 15px;
            height: 15px;
            background-color: #51D327;
            border: 2px solid #ffffff;
            z-index: 11;
            border-radius: 50px;
          }
        }
        .title{
          span{
            font-size: 14px;
            font-family: 'Montserrat-SemiBold', 'sans-serif';
            color: #000000;
            line-height: 14px;
            &.sub-title{
              font-size: 10px;
              font-family: 'Montserrat-Regular', 'sans-serif';
              color: #626262;
            }
          }
        }
      }
      .card-body{
        height: 40vh;
        overflow-y: auto;
        background: url('../../img/wa.png');
        background-size: cover;
        background-repeat: no-repeat;
        .msg {
          width: 100%;
          height: auto;
          display: block;
          overflow: hidden;
          .bubble {
            float: left;
            max-width: 75%;
            width: auto;
            height: auto;
            display: block;
            background: #f3f3f3;
            border-radius: 5px;
            position: relative;
            margin: 10px 0 3px 10px;
            box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
            &.alt {
              margin: 10px 25px 3px 0px;
              background: #DCF8C6;
              float: right;
            }
            &.follow {
              margin: 2px 0 3px 25px;
            }
            &.altfollow {
              margin: 2px 25px 3px 0px;
              background: #DCF8C6;
              float: right;
            }
            .txt {
              padding: 8px 0 8px 0;
              width: 100%;
              min-height: 54px;
              position: relative;
              .name {
                font-weight: 600;
                font-size: 14px;
                display: inline-table;
                padding: 0 0 0 15px;
                margin: 0 0 4px 0;
                color: #3498db;
                span {
                  font-weight: normal;
                  color: #b3b3b3;
                  overflow: hidden;
                }
                &.alt {
                  color: #2ecc51;
                }
              }
              .message {
                font-size: 12px;
                font-weight: 500;
                padding: 0 15px 0 15px;
                margin: auto;
                color: #2b2b2b;
                display: table;
                line-height: 20px;
              }
              .timestamp {
                font-size: 10px;
                margin: auto;
                padding: 0 15px 0 0;
                display: table;
                float: right;
                position: absolute;
                text-transform: uppercase;
                color: #999;
                bottom: 0;
                right: 0;
              }
            }
            .bubble-arrow {
              position: absolute;
              float: left;
              left: -11px;
              top: 0px;
              &.alt {
                bottom: 20px;
                left: auto;
                right: 4px;
                float: right;
              }
            }
            .bubble-arrow:after {
              content: "";
              position: absolute;
              border-top: 15px solid #f3f3f3;
              border-left: 15px solid transparent;
              border-radius: 4px 0 0 0px;
              width: 0;
              height: 0;
            }
            .bubble-arrow.alt:after {
              border-top: 15px solid #DCF8C6;
              transform: scaleX(-1);
            }
          }
        }
      }
      .card-footer {
        border-radius: 0 0 15px 15px;
        background-color: #ffffff;
        border: 0;
        .btn-wa{
          border-radius: 100px;
          color: #ffffff;
          background: #20B038;
          padding: 10px 30px;
        }
      }
    }
  }
}
